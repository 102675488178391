* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  color: #111;
  font-family: HalenoirCompact-Regular;
  font-size: 18px;
  font-weight: 400;
}

@font-face {
  font-family: HalenoirCompact-Regular;
  src: url("HalenoirCompact-Regular.855d7c8a.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: HalenoirCompact-DemiBold;
  src: url("HalenoirCompact-DemiBold.17ccf55a.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: HalenoirCompact-Light;
  src: url("HalenoirCompact-Light.999be9f8.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: HalenoirCompact-ExtraLight;
  src: url("HalenoirCompact-ExtraLight.1e75c4df.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: #fff;
  text-decoration: none;
  transition: all .3s;
}

h2 {
  font-family: HalenoirCompact-DemiBold;
  font-size: 166.387px;
}

header {
  z-index: 999;
  width: 100%;
  position: fixed;
  top: 0;
}

.header-wrapper {
  justify-content: space-between;
  align-items: center;
  padding: 20px 32px;
  transition: all .3s;
  display: flex;
}

.header-left {
  z-index: 900;
  align-items: center;
  display: flex;
  position: relative;
}

.header-right {
  width: 100%;
  max-width: 133px;
}

.header-left img {
  width: 100%;
  max-width: 68px;
  margin-left: 12px;
}

.outline-btn {
  color: #111;
  text-align: center;
  border: 1px solid #111;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 133px;
  padding: 12px 15px;
  font-family: HalenoirCompact-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  transition: all .3s;
  display: flex;
}

.outline-btn:hover {
  color: #fff;
  background-color: #111;
}

.outline-btn span {
  background: #111;
  width: 5px;
  height: 5px;
  margin-top: 3.5px;
  margin-left: 6px;
  transition: all .3s;
}

.outline-btn:hover span {
  background-color: #fff;
}

.white-outline-btn {
  color: #fff;
  border: 1px solid #fff;
}

.white-outline-btn:hover {
  color: #111;
  background-color: #fff;
}

.white-outline-btn span {
  background: #fff;
}

.white-outline-btn:hover span {
  background: #111;
}

.sticky {
  z-index: 9999;
  width: 100%;
  position: fixed;
  top: 0;
}

#myHeader.sticky {
  background-color: #fff;
  box-shadow: 1px 4px 13px #0000000d;
}

#myHeader.sticky .header-wrapper {
  padding: 12px 32px;
}

.menu-open .header-wrapper .header-right {
  display: none;
}

.menu-open .header-wrapper {
  z-index: 999;
  background-color: #ff8772;
  width: 100%;
  position: relative;
}

.menu-open .menu-lines .line:first-child {
  transform: translateY(2px)rotate(45deg);
  -webkit-transform: translateY(3px) rotate(10);
  background-color: #1f2937;
  -moz-transform: translateY(3px)rotate(45deg);
  -ms-transform: translateY(3px)rotate(45deg);
  -o-transform: translateY(3px)rotate(45deg);
}

.menu-open .menu-lines .line:nth-child(3) {
  transform: translateY(-4px)rotate(135deg);
  -webkit-transform: translateY(0) rotate(10);
  background-color: #1f2937;
  -moz-transform: translateY(-5px)rotate(135deg);
  -ms-transform: translateY(-5px)rotate(135deg);
  -o-transform: translateY(-5px)rotate(135deg);
}

.menu-open .menu-lines .line:nth-child(2) {
  display: none;
}

.menu-lines .line {
  -o-transition: all .3s ease-in-out;
  z-index: 999;
  background-color: #111;
  width: 34px;
  height: 2px;
  margin: 4px auto;
  transition: all .3s ease-in-out;
  display: block;
  position: relative;
}

.menu-overlay.is-active {
  opacity: .8;
  background: var(--alternative-black, #111);
  backdrop-filter: blur(6px);
  z-index: 998;
  width: 100%;
  height: 100%;
  transition: all .6s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

.main-menu {
  opacity: 0;
  visibility: hidden;
  max-height: 100vh;
  transition: all .3s;
  position: fixed;
  top: -100%;
  overflow-y: auto;
}

.menu-btn {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 73px;
  height: 45px;
  display: flex;
}

.menu-open .main-menu {
  opacity: 1;
  visibility: visible;
  top: 0;
}

.main-menu {
  background-color: #ff8772;
  width: 100%;
}

.main-menu .main-menu-wrapper {
  align-items: end;
  display: flex;
}

.main-menu .manu-left {
  border-right: .5px solid #00000026;
  width: 60%;
  padding: 108px 122px 94px;
}

.main-menu .manu-right {
  width: 40%;
}

.main-menu .manu-left ul li a {
  color: #111;
  font-family: HalenoirCompact-ExtraLight;
  font-size: 64px;
  font-style: normal;
  font-weight: 200;
  line-height: 1.55;
}

.main-menu .manu-left ul li a span {
  opacity: 0;
  background-color: #000;
  width: 15px;
  height: 15px;
  transition: all .3s;
  display: inline-block;
}

.main-menu .manu-left ul li a:hover span {
  opacity: 1;
}

.manu-right .address-text {
  color: #111;
  width: 100%;
  max-width: 290px;
  margin-bottom: 52px;
  margin-left: 71px;
  font-family: HalenoirCompact-Light;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2;
}

.manu-right .address-text p {
  margin-bottom: 25px;
}

.manu-right .address-text a {
  color: #111;
  display: block;
}

.manu-right ul {
  border-top: .5px solid #00000026;
  align-items: center;
  gap: 25px;
  padding: 96px 71px 76px;
  display: flex;
}

body.menu-open {
  overflow-y: hidden;
  height: 100vh !important;
}

.manu-right .address-text a:hover {
  color: #fff;
}

.manu-right ul a:hover {
  opacity: .7;
}

footer {
  background-color: #111;
  padding-top: 92px;
  position: relative;
}

.footer-wrapper {
  border-top: 1px solid #fff;
  width: 100%;
  max-width: calc(100% - 14vw);
  margin: 0 auto;
  padding-top: 17px;
  padding-bottom: 95px;
  display: flex;
}

.footer-left {
  border-right: 1px solid #fff;
  width: 100%;
  max-width: 50%;
  padding-top: 15px;
  padding-right: 36px;
}

.footer-left .address-text {
  color: #fff;
  width: 100%;
  max-width: 290px;
  font-family: HalenoirCompact-Light;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2;
}

.footer-left .address-text p {
  margin-bottom: 25px;
  line-height: 1.1;
}

.footer-left .address-text a {
  color: #fff;
  display: block;
}

.footer-left ul {
  gap: 25px;
  margin: 57px 0;
  display: flex;
}

.footer-left-tagline.show-mobile {
  display: none;
}

.footer-left-tagline {
  justify-content: space-between;
  display: flex;
}

.footer-left-tagline p {
  color: #fff;
  font-family: HalenoirCompact-ExtraLight;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.1;
}

.footer-right {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 50%;
  padding-top: 15px;
  padding-left: 52px;
  display: flex;
}

.footer-right-links {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

footer a:hover {
  color: #ff8772 !important;
}

.footer-right-links a {
  color: #fff;
  font-family: HalenoirCompact-Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.84;
}

.footer-right-links a:hover, .footer-left ul a:hover {
  opacity: .7;
}

.apt-residential-text {
  color: #fff;
  justify-content: space-between;
  font-family: HalenoirCompact-Light;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.31;
  display: flex;
}

.apt-residential-text p {
  display: block;
}

.apt-residential-text a {
  color: #fff;
  font-family: HalenoirCompact-Light;
  text-decoration: underline;
}

.social-icons {
  display: none;
}

.hero-section {
  align-items: center;
  min-height: 100vh;
  display: flex;
}

.hero-section-wrapper {
  width: 100%;
  position: relative;
  left: -2%;
}

.home-page-hero {
  z-index: 100;
  margin-bottom: 7px;
  display: flex;
  position: relative;
}

.home-page-hero img {
  width: 52vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.hero-images img {
  position: absolute;
}

.future-living-section h2 {
  color: #ff8772;
  position: relative;
}

.future-living-wrapper {
  justify-content: space-evenly;
  align-items: center;
  padding: 39px 0 27px;
  display: flex;
  position: relative;
}

.future-living-wrapper h2 {
  display: inline-block;
}

.show-mobile {
  display: none;
}

.future-living-wrapper .about-us-content {
  max-width: 27vw;
}

.about-us-content p {
  color: #fff;
  width: 100%;
  max-width: 309px;
  margin-bottom: 25px;
  font-family: HalenoirCompact-Light;
  font-size: 17px;
  font-style: normal;
  line-height: 22px;
}

.future-living-image {
  align-items: center;
  width: 100%;
  max-width: 17.5vw;
  display: flex;
  position: relative;
}

.future-living-1 {
  z-index: 2;
  width: 18vw;
  position: relative;
  left: 8px;
}

.future-living-2 {
  z-index: 1;
  width: 28.5vw;
  position: absolute;
  left: -6.2vw;
}

.future-living-3 {
  z-index: 0;
  width: 21.5vw;
  position: absolute;
  left: -4.2vw;
}

.future-thinkers {
  text-align: right;
}

.third-image-section {
  padding: 83px 0 98px;
}

.third-image-section .third-image-row {
  position: relative;
}

.third-image-wrapper {
  z-index: 50;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.third-image-section .third-image-row .box-wrapper {
  background: #95dcd5;
  align-items: center;
  width: 16.9vw;
  padding: 18px 19px;
  display: flex;
}

.third-image-section .third-image-row .box-wrapper img {
  width: 100%;
}

.third-image-section .third-image-row video {
  object-fit: cover;
  width: 25.8vw;
  height: 25.8vw;
}

.future-thinkers-2 {
  width: 37.8vw;
  position: relative;
  top: -11px;
  left: 15px;
}

.video-frame-1 {
  align-items: center;
  width: 17.8vw;
  margin-right: 0;
  display: flex;
}

.marquee-text {
  z-index: 0;
  width: 100vw;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.marquee-text img {
  width: 14vw;
}

.marquee-text div {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.third-image-section .third-image-row:first-child {
  margin-bottom: 39px;
}

.video-frame-2 {
  align-items: center;
  margin-left: -7.3vw;
  display: flex;
}

.third-image-section .third-image-row.second-row .box-wrapper {
  background: #111;
  position: relative;
  left: -.4vw;
}

.future-thinkers-4 {
  width: 38vw;
  margin-top: 3.9vw;
  margin-right: 3.7vw;
}

.crafting-living-section {
  background: #f0f0f0;
  display: flex;
}

.crafting-living-content {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 83px 20px 28.6vw;
}

.crafting-living-video-left .crafting-living-video {
  background: #ff8772;
}

.crafting-living-video {
  height: 50vw;
}

.crafting-living-video video {
  object-fit: cover;
  height: 100%;
}

.crafting-living-left .crafting-living-content p {
  color: #000;
  margin-bottom: 20px;
  font-family: HalenoirCompact-ExtraLight;
  font-size: 55px;
  font-style: normal;
}

.crafting-living-right, .crafting-living-left, .crafting-living-video-right, .crafting-living-video-left {
  width: 50%;
}

.a-big-text {
  justify-content: center;
  padding: 19.2vw 0 17.2vw;
  display: flex;
}

.a-big-text img {
  width: 33.4vw;
}

.crafting-living-video {
  padding: 43px;
}

.crafting-living-video-right .crafting-living-video {
  background: #000;
  margin-top: -4px;
}

.crafting-living-video-left {
  margin-top: -21.2vw;
}

.crafting-living-video-left .crafting-living-deatils {
  width: 100%;
  max-width: 465px;
  margin: 0 auto;
  padding: 7.8vw 0 6vw;
}

.crafting-living-video-left .crafting-living-deatils h3 {
  color: #111;
  margin-bottom: 30px;
  font-family: HalenoirCompact-Light;
  font-size: 36px;
  line-height: 39px;
}

.crafting-living-video-left .crafting-living-deatils p {
  color: #111;
  max-width: 465px;
  margin-bottom: 30px;
  font-family: HalenoirCompact-ExtraLight;
  font-size: 17px;
  font-style: normal;
  line-height: 1.32;
}

.crafting-living-video-section, .crafting-living-video-section .crafting-living-left {
  background-color: #fff;
  display: flex;
}

.new-standard-section {
  padding: 0 60px 155px;
}

.new-standard-section .new-standard-one span {
  margin-right: 89px;
}

.new-standard-section .new-standard-two {
  justify-content: space-between;
  margin-right: 1px;
  display: flex;
}

.new-standard-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.new-standard-wrapper h2 {
  color: #95dcd5;
  position: relative;
  top: -1.39vw;
  left: -5.8vw;
}

.new-standard-image {
  z-index: -1;
  width: 100%;
  max-width: 500px;
  position: relative;
  inset: 0;
}

.new-standard-image img {
  width: 100%;
}

.new-standard-1 {
  z-index: 50;
  position: absolute;
  right: 0;
}

.new-standard-2 {
  z-index: 20;
  position: relative;
  right: 0;
}

.new-standard-3 {
  z-index: 0;
  position: absolute;
  bottom: .9vw;
  right: .9vw;
}

.maecenas-maximus-section {
  background-color: #111;
}

.maecenas-maximus-wrapper {
  width: 100%;
  max-width: calc(100% - 22vw);
  margin: 0 auto;
  padding: 65px 20px 54px;
}

.maecenas-maximus-textcontent {
  width: 100%;
  max-width: 650px;
}

.maecenas-maximus-textcontent p {
  color: #fff;
  margin-bottom: 28px;
  font-family: HalenoirCompact-ExtraLight;
  font-size: 40px;
}

.maecenas-maximus-item {
  position: relative;
}

.maecenas-maximus-item.blend-mode-class:before {
  content: "";
  mix-blend-mode: color;
  background: #d9d9d9;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  position: absolute;
}

.maecenas-maximus-item video {
  object-fit: cover;
  z-index: -1;
  border-radius: 24px;
  height: 525px;
  display: block;
  position: relative;
}

.maecenas-maximus-slider .owl-item:nth-child(2n) {
  margin-top: 26px;
}

.maecenas-maximus-slider .video-content-logo-bottom {
  z-index: 1;
  position: absolute;
  bottom: 33px;
  left: 50%;
  transform: translateX(-50%);
}

.maecenas-maximus-slider .video-content-logo-top {
  position: absolute;
  top: 33px;
  left: 50%;
  transform: translateX(-50%);
}

.maecenas-maximus-item .video-content-text {
  text-align: center;
  color: #fff;
  width: 100%;
  max-width: 246px;
  font-family: HalenoirCompact-Light;
  font-size: 34px;
  font-weight: 300;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.maecenas-maximus-bottom-logo {
  justify-content: center;
  padding: 118px 20px 0;
  display: flex;
}

.maecenas-maximus-bottom-logo img {
  width: 24vw;
}

.new-standard-section.about-ushero-section {
  padding: 10.7vw 44px 10.8vw;
  position: relative;
}

.new-standard-section.about-ushero-section .new-standard-two {
  justify-content: end;
  gap: 36px;
  margin-right: -10px;
}

.new-standard-section.about-ushero-section .new-standard-two span {
  color: #ff8772;
}

.new-standard-section.about-ushero-section .new-standard-wrapper {
  align-items: flex-start;
}

.new-standard-section.about-ushero-section .new-standard-wrapper h2 {
  color: #111;
}

.new-standard-section.about-ushero-section .new-standard-wrapper p {
  width: 100%;
  max-width: 230px;
  margin-top: 6.5vw;
  margin-left: 10.5vw;
  font-size: 16px;
  line-height: 1.3;
}

.new-standard-for-details {
  width: 100%;
  max-width: 230px;
}

.new-standard-section.about-ushero-section .new-standard-image video {
  object-fit: cover;
  width: 100%;
}

.new-standard-section.about-ushero-section .new-standard-wrapper h2 {
  color: #95dcd5;
  position: relative;
  top: -1vw;
  left: -1vw;
}

.our-mission-row-one {
  justify-content: space-between;
  width: 100%;
  max-width: 94.5vw;
  margin-left: auto;
  padding-bottom: 6vw;
  display: flex;
}

.our-mission-text {
  text-align: center;
  margin-top: 7.05vw;
}

.our-mission-text h3 {
  text-align: center;
  width: 100%;
  max-width: 608px;
  margin-top: 35px;
  font-family: HalenoirCompact-ExtraLight;
  font-size: 36px;
  font-style: normal;
  font-weight: 200;
  line-height: 42px;
}

.our-mission-text p span {
  background: #95dcd5;
  width: 16px;
  height: 16px;
}

.our-mission-text p {
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-family: HalenoirCompact-Light;
  font-size: 16px;
  display: flex;
}

.our-mission-img-1 img {
  width: 18vw;
  position: relative;
  top: 1.5vw;
  left: 1.3vw;
}

.our-mission-img-2 img {
  width: 23.5vw;
  height: 26.1vw;
  margin-top: 4.7vw;
}

.our-mission-row-two {
  justify-content: space-between;
  width: 100%;
  max-width: 92.5vw;
  padding-bottom: 7.9vw;
  display: flex;
}

.our-mission-img-3 img {
  width: 21vw;
  position: relative;
  top: 5.3vw;
}

.our-mission-img-4 img {
  width: 24.5vw;
  position: relative;
  top: 2.2vw;
  left: -.8vw;
}

.our-mission-img-5 img {
  width: 20.6vw;
}

.our-purpose-section {
  background-color: #111;
  padding-bottom: 18.2vw;
}

.our-purpose-section h3 {
  color: #fff;
}

.our-purpose-line-one {
  justify-content: space-between;
  padding: 112px 8.6vw 123px;
  display: flex;
}

.our-purpose-line-one h3 {
  width: 100%;
  max-width: 606px;
  font-family: HalenoirCompact-ExtraLight;
  font-size: 64px;
  font-style: normal;
  font-weight: 200;
  line-height: 1.16;
}

.our-purpose-text {
  color: #fff;
  width: 100%;
  max-width: 433px;
  padding-top: 13px;
  font-family: HalenoirCompact-Light;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.31;
}

.our-purpose-text .sub-title {
  align-items: center;
  gap: 12px;
  margin-bottom: 22px;
  display: flex;
}

.our-purpose-text .sub-title span {
  background: #ff8772;
  width: 16px;
  height: 16px;
  display: block;
}

.our-purpose-line-two {
  justify-content: space-between;
  align-items: end;
  padding-right: 43px;
  display: flex;
}

.our-purpose-line-two .our-purpose-a-logo img {
  width: 100%;
  max-width: 33vw;
  position: relative;
  bottom: -2vw;
}

.founders-list-wrapper {
  flex-direction: column;
  width: 100%;
  max-width: 889px;
  margin-left: auto;
  display: flex;
}

.founders-list-wrapper .founders-list-main:first-child {
  margin-bottom: 5.7vw;
}

.founders-list-main {
  color: #fff;
  gap: 36px;
  margin-bottom: 8.3vw;
  font-family: HalenoirCompact-Light;
  display: flex;
}

.founders-list-main .founder-items {
  width: 100%;
  max-width: 272px;
}

.founders-list-main h4 {
  border-bottom: 1px solid #fff;
  margin-bottom: 5px;
  padding-bottom: 5px;
  font-size: 20px;
}

.founders-list-main p {
  opacity: .5;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.3;
}

.about-us-crafting-living-video {
  position: relative;
}

.about-us-crafting-living-video .crafting-living-video-left {
  margin-top: -18.3vw;
}

.about-us-crafting-living-video .crafting-living-video-left .crafting-living-video {
  background-color: #95dcd5;
}

.about-us-crafting-living-video .crafting-living-video-right .crafting-living-video {
  background-color: #ff8772;
}

.about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils {
  width: 100%;
  max-width: 443px;
  margin: 0 auto;
  padding: 7.4vw 0 0;
}

.about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils h3 {
  margin-bottom: 35px;
  line-height: 1.08;
}

.about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils p {
  max-width: 435px;
  line-height: 131.7%;
}

.about-us-crafting-living-video .crafting-living-video-right .blend-mode-class {
  height: 100%;
  position: relative;
}

.logo-video-section {
  margin-top: 0;
  padding-bottom: 14.2vw;
}

.logo-video-wrapper {
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.logo-video-wrapper div.full-stop img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.logo-video-wrapper div {
  width: 100%;
  position: relative;
}

.logo-video-wrapper div video {
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-video-section h2 {
  mix-blend-mode: screen;
  z-index: 1;
  background-color: #fff;
  width: 101%;
  font-family: HalenoirCompact-DemiBold;
  font-size: 60vw;
  line-height: 1;
  position: relative;
}

.join-the-team-wrapper {
  position: relative;
}

.join-the-team-wrapper video {
  object-fit: cover;
  width: 100%;
  height: 46.5vw;
  display: block;
}

.join-the-team-wrapper:before {
  content: "";
  z-index: 5;
  background: linear-gradient(0deg, #00000040 0% 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.join-the-team-textcontent {
  z-index: 100;
  position: absolute;
  bottom: 4.7vw;
  left: 10.8vw;
}

.join-the-team-textcontent h5 {
  color: #fff;
  width: 100%;
  max-width: 665px;
  margin-bottom: 35px;
  font-family: HalenoirCompact-ExtraLight;
  font-size: 64px;
  font-style: normal;
  font-weight: 200;
  line-height: 108%;
}

.video-and-text-wrapper {
  display: flex;
  position: relative;
}

.video-and-text-wrapper .video-overlay {
  mix-blend-mode: color;
  z-index: 100;
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-and-text-wrapper video {
  object-fit: cover;
  height: 45.1vw;
  min-height: 506px;
}

.video-and-text-wrapper h2 {
  z-index: 200;
  line-height: 1;
  position: absolute;
  top: 25%;
  left: 12.3vw;
}

.our-expertise-content-section {
  background-color: #111;
  padding: 6.8vw 0 0;
}

.our-expertise-content-section p {
  color: #fff;
  width: 100%;
  max-width: 1087px;
  margin: 0 auto;
  font-family: HalenoirCompact-Light;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.16;
}

.our-expertise-content-images {
  min-height: 300px;
  position: relative;
}

.our-expertise-content-images .our-expertise-a-bg img:not(.our-expertise-a-text) {
  width: 100%;
  height: 100%;
}

.our-expertise-content-images .our-expertise-a-bg {
  z-index: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  position: relative;
  top: 0;
  bottom: 0;
}

.our-expertise-content-images .our-expertise-a-text {
  z-index: 50;
  width: 100%;
  max-width: 17vw;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.view-our-team-section {
  padding: 10.77vw 20px 9vw;
}

.content-title {
  color: #111;
  margin-bottom: 20px;
  font-family: HalenoirCompact-Light;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.08;
}

.content-details {
  text-overflow: ellipsis;
  font-family: HalenoirCompact-Light;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.31;
}

.view-our-team-image {
  max-width: 526.721px;
  padding-top: 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.view-our-team-image .view-our-team-1 {
  z-index: 50;
  width: 100%;
  position: absolute;
  top: 1.7vw;
  left: -.1vw;
  transform: rotate(4.075deg);
}

.view-our-team-image .view-our-team-2 {
  z-index: 10;
  width: 100%;
  position: absolute;
  top: 2vw;
  left: -.7vw;
  transform: rotate(-7deg);
}

.view-our-team-image .view-our-team-3 {
  z-index: 0;
  width: 100%;
  position: relative;
  bottom: -2vw;
  left: -.8vw;
  transform: rotate(0);
}

.view-our-team-one {
  justify-content: space-between;
  margin-bottom: 3vw;
  padding-left: 10.7vw;
  padding-right: 5.9vw;
  display: flex;
}

.view-our-team-two {
  justify-content: space-between;
  margin-top: -6.8vw;
  margin-bottom: 2.8vw;
  padding-left: 7vw;
  padding-right: 4.2vw;
  display: flex;
}

.view-our-team-content-1 {
  width: 100%;
  max-width: 386px;
  margin-top: 4.2vw;
}

.view-our-team-content-1 h3 {
  margin-bottom: 13px;
}

.view-our-team-content-2 {
  width: 100%;
  max-width: 419px;
}

.view-our-team-content-3 {
  width: 100%;
  max-width: 346px;
  margin-top: 2.9vw;
}

.view-our-team-content-3 h3 {
  width: 100%;
  max-width: 246px;
}

.view-our-team-content-4 {
  width: 100%;
  max-width: 303px;
}

.view-our-team-button {
  justify-content: center;
  display: flex;
}

.view-our-team-button .outline-btn {
  max-width: 151px;
}

.our-values-section {
  padding: 8vw 72px;
  overflow: hidden;
}

.our-values-first-row {
  display: flex;
}

.our-values-text-character div {
  position: relative;
  overflow: hidden;
}

.our-values-text-character .text-character-bg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.our-values-text-character .text-character {
  mix-blend-mode: screen;
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.our-values-text {
  width: 100%;
  max-width: 687px;
  margin-left: auto;
}

.our-values-text p {
  align-items: center;
  gap: 12px;
  margin-bottom: 18px;
  font-family: HalenoirCompact-Light;
  font-size: 16px;
  display: flex;
}

.our-values-text p span {
  background: #ff8772;
  width: 16px;
  height: 16px;
}

.our-values-text h3 {
  text-align: center;
  text-align: left;
  width: 100%;
  margin-top: 17px;
  font-family: HalenoirCompact-ExtraLight;
  font-size: 64px;
  font-style: normal;
  font-weight: 200;
}

.our-values-content-one {
  justify-content: space-between;
  display: flex;
}

.our-values-content-2 {
  width: 100%;
  max-width: 425px;
  margin-left: 12.4vw;
}

.our-values-content-two {
  display: flex;
}

.our-values-content-two-wrapper {
  width: 100%;
}

.our-values-content-3 {
  width: 100%;
  max-width: 436px;
  margin-bottom: 7vw;
  margin-left: 7.2vw;
  padding: 4vw 0;
}

.our-community-hero-section .video-and-text-wrapper h2 {
  top: 36%;
  left: 8.5vw;
}

.our-values-section.our-community-content-section {
  padding: 4.6vw 57px 5.8vw;
}

.our-community-content-section .our-values-first-row {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.our-community-content-section .our-values-text {
  margin-left: 6vw;
}

.our-community-content-section .our-values-text h3 {
  margin-left: 3px;
}

.community-hero-image img {
  width: 21.5vw;
  height: 29.5vw;
  margin-top: 10.2vw;
}

.dolor-sitamet-1 {
  margin-left: 5.2vw;
  display: flex;
}

.dolor-sitamet-section {
  background-color: #f0f0f0;
  padding: 3.8vw 0 11.1vw;
}

.dolor-sitamet-section p {
  font-family: HalenoirCompact-Light;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.3;
}

.dolor-sitamet-text-1 {
  margin-top: -.5vw;
  margin-left: 4.8vw;
}

.dolor-sitamet-text-1 p {
  width: 100%;
  max-width: 398px;
  margin-top: 1vw;
  margin-left: 5.2vw;
}

.dolor-sitamet-img-1 img {
  width: 16.2vw;
  height: 23.2vw;
  margin-top: 6vw;
}

.dolor-sitamet-2 {
  display: flex;
  position: relative;
}

.dolor-sitamet-img-2 {
  align-items: end;
  display: flex;
}

.dolor-sitamet-img-2 img {
  width: 18.3vw;
  height: 9.9vw;
  margin-bottom: -12.5vw;
}

.dolor-sitamet-text-2 {
  margin-top: 6.5vw;
  margin-left: -.6vw;
}

.dolor-sitamet-text-2 p {
  width: 100%;
  max-width: 441px;
  margin-top: -11px;
  margin-left: 9.1vw;
}

.dolor-sitamet-img-3 {
  position: absolute;
  top: -7.5vw;
  right: 0;
}

.dolor-sitamet-3 {
  width: 100%;
  max-width: 74.5vw;
  margin-left: auto;
  padding-top: 9.9vw;
  display: flex;
  position: relative;
}

.dolor-sitamet-img-4 {
  position: absolute;
  bottom: -7.3vw;
  left: 2.1vw;
}

.dolor-sitamet-text-3 p {
  max-width: 441px;
  margin-left: 29.3vw;
  padding-top: 53px;
}

.dolor-sitamet-img-3 img {
  width: 31vw;
  height: 22vw;
}

.dolor-sitamet-img-4 img {
  width: 14.7vw;
  height: 14.4vw;
}

.social-responsibility-section {
  padding: 150px 0;
}

.sustainability-text {
  color: #77d797;
}

.responsibility-text {
  color: #77d797;
  text-align: right;
  margin-right: 1.5vw;
}

.social-responsibility-wrapper {
  align-items: center;
  margin-top: 1.3vw;
  margin-left: 2.3vw;
  display: flex;
}

.social-responsibility-images {
  width: 100%;
  max-width: 364px;
  position: relative;
}

.social-responsibility-images .social-responsibility-img-1 {
  z-index: 50;
  width: 100%;
  max-width: 17.5vw;
  position: relative;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.social-responsibility-images .social-responsibility-img-2 {
  z-index: 10;
  width: 100%;
  max-width: 21.5vw;
  position: absolute;
  left: 0;
}

.social-responsibility-images .social-responsibility-img-3 {
  z-index: 0;
  width: 100%;
  max-width: 20.5vw;
  position: absolute;
  right: 0;
}

.social-responsibility-wrapper h2 {
  margin-left: 3vw;
}

.contact-us-form-section {
  padding: 131px 20px 0;
}

.contact-us-title {
  padding: 0 32px;
}

.contact-us-title h2 {
  margin-bottom: 57px;
}

.contact-us-title p {
  margin-bottom: 27px;
  font-family: HalenoirCompact-Light;
}

.contact-us-form-wrapper {
  border-top: .5px solid #111;
  margin: 0 16px;
  padding: 17px 0;
  display: flex;
}

.contact-us-form-left {
  border-right: .5px solid #111;
  width: 100%;
  max-width: 50%;
  padding-top: 59px;
  padding-bottom: 80px;
  padding-left: 40px;
}

.contact-details-item {
  margin-bottom: 48px;
}

.contact-details-item span {
  color: #ff8772;
  margin-bottom: 9px;
  font-family: HalenoirCompact-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.09;
  display: block;
}

.contact-details-item a {
  display: block;
}

.contact-details-item a:hover {
  color: #ff8772;
}

.contect-us-map {
  width: 100%;
  max-width: 644px;
  padding-right: 37px;
  position: relative;
}

.contect-us-map iframe {
  width: 100%;
}

.contact-us-form-right {
  width: 100%;
  max-width: 50%;
  padding: 59px 65px 51px 51px;
}

.input-field {
  margin-bottom: 50px;
}

.input-field label {
  color: #111;
  width: 100%;
  margin-bottom: 27px;
  padding-left: 7px;
  font-family: HalenoirCompact-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.09;
  display: block;
}

.input-field textarea:focus, .input-field input:focus {
  outline: none;
}

.input-field textarea::placeholder, .input-field input::placeholder {
  color: #787878;
  font-family: HalenoirCompact-ExtraLight;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 1.09;
}

.input-field textarea, .input-field input {
  border: 0;
  border-bottom: .5px solid #111;
  width: 100%;
  padding-bottom: 9px;
  padding-left: 7px;
  font-size: 16px;
  display: block;
}

.send-message-btn {
  justify-content: end;
  margin-top: 96px;
  display: flex;
}

.send-message-btn button {
  cursor: pointer;
  background-color: #fff;
  width: 100%;
  max-width: 144px;
}

.news-hero-section {
  padding-bottom: 21vw;
}

.news-hero-section .video-and-text-wrapper {
  flex-direction: column;
  position: relative;
}

.news-hero-section .video-and-text-wrapper h2 {
  text-align: center;
  width: 100%;
  top: 18%;
  left: 0;
  transform: translateX(0);
}

.first-products-article-item {
  z-index: 500;
  background: #95dcd5;
  width: 100%;
  max-width: 51.5vw;
  padding: 35px 30px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.first-products-article-item img {
  width: 100%;
}

.first-products-article-item p {
  color: #111;
  padding: 25px 0;
  font-family: HalenoirCompact-Light;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.09;
}

.first-products-article-item a {
  max-width: 114px;
}

.blogs-main {
  width: 100%;
  max-width: 92.5vw;
  margin: 0 auto;
  padding: 5vw 0 7vw;
}

.blog-list {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4vw;
  display: grid;
}

.blog-list-item {
  background-color: #f0f0f0;
  padding: 15px 15px 25px;
}

.blog-list-item IMG {
  width: 100%;
}

.blog-list-item p {
  color: #111;
  padding: 20px 0;
  font-family: HalenoirCompact-Light;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.09;
}

.blog-list-item a {
  max-width: 114px;
}

.main-article-section {
  width: 100%;
  max-width: 1130px;
  margin: 0 auto;
  padding: 8.5vw 20px 6.5vw;
}

.main-article-section h3 {
  color: #111;
  font-family: HalenoirCompact-Light;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.16;
}

.main-article-section p {
  color: #111;
  font-family: HalenoirCompact-Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.31;
}

.article-open-main h3 {
  padding-top: 52px;
}

.article-open-wrapper {
  justify-content: space-between;
  margin-top: 34px;
  display: flex;
}

.article-open-text-content {
  width: 100%;
  max-width: 507px;
}

.article-open-text-content P {
  margin-bottom: 21px;
}

.article-open-row-two {
  gap: 2.1vw;
  padding-top: 4.3vw;
  display: flex;
}

.article-open-two-left {
  max-width: 27.3vw;
}

.article-open-two-left h3 {
  margin-top: 4.5vw;
  padding-left: 4px;
}

.article-open-only-text {
  margin-top: 3.3vw;
  padding-left: 4px;
}

.article-open-only-text p {
  margin-bottom: 20px;
}

.other-recent-articles-section {
  border-top: .5px solid #111;
  width: 100%;
  max-width: 1370px;
  margin: 0 auto;
  padding: 8vw 0 0;
}

.other-recent-articles-section .our-values-text {
  margin: 0;
  padding-left: 26px;
}

.other-recent-articles-section .blogs-main {
  padding: 2.2vw 0 7vw;
}
/*# sourceMappingURL=index.5de7a00b.css.map */

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html {
	overflow-x: hidden;
	scroll-behavior: smooth;
}

body {
	font-family: "HalenoirCompact-Regular";
	font-size: 18px;
	font-weight: 400;
	color: #111;
}
/* --------------- font face start --------------- */
@font-face {
	font-family: "HalenoirCompact-Regular";
	src: url("../fonts/HalenoirCompact-Regular.otf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "HalenoirCompact-DemiBold";
	src: url("../fonts/HalenoirCompact-DemiBold.otf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "HalenoirCompact-Light";
	src: url("../fonts/HalenoirCompact-Light.otf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "HalenoirCompact-ExtraLight";
	src: url("../fonts/HalenoirCompact-ExtraLight.otf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

ul,
li {
	list-style: none;
	padding: 0;
	margin: 0;
}

a {
	text-decoration: none;
	color: #fff;
	transition: 0.3s all;
}

h2 {
	font-size: 166.387px;
	font-family: "HalenoirCompact-DemiBold";
}

/* --------------- header start ---------------*/
header {
	position: fixed;
	top: 0;
	z-index: 999;
	width: 100%;
}

.header-wrapper {
	padding: 20px 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: 0.3s;
}

.header-left {
	display: flex;
	position: relative;
	z-index: 900;
	align-items: center;
}

.header-right {
	max-width: 133px;
	width: 100%;
}

.header-left img {
	width: 100%;
	max-width: 68px;
	margin-left: 12px;
}

.outline-btn {
	border: 1px solid #111111;
	width: 100%;
	max-width: 133px;
	padding: 12px 15px;
	font-family: "HalenoirCompact-Regular";
	color: #111;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s;
}

.outline-btn:hover {
	background-color: #111;
	color: #fff;
}

.outline-btn span {
	width: 5px;
	height: 5px;
	margin-left: 6px;
	background: #111;
	transition: 0.3s;
	margin-top: 3.5px;
}

.outline-btn:hover span {
	background-color: #fff;
}

.white-outline-btn {
	border: 1px solid #fff;
	color: #fff;
}

.white-outline-btn:hover {
	background-color: #fff;
	color: #111;
}

.white-outline-btn span {
	background: #fff;
}

.white-outline-btn:hover span {
	background: #111;
}

.sticky {
	position: fixed;
	z-index: 9999;
	top: 0;
	width: 100%;
}

#myHeader.sticky {
	background-color: #ffffff;
	box-shadow: 1px 4px 13px #0000000d;
}

#myHeader.sticky .header-wrapper {
	padding: 12px 32px;
}

.menu-open .header-wrapper .header-right {
	display: none;
}

.menu-open .header-wrapper {
	background-color: #ff8772;
	width: 100%;
	position: relative;
	z-index: 999;
}

.menu-open .menu-lines .line:nth-child(1) {
	transform: translateY(2px) rotate(45deg);
	-webkit-transform: translateY(3px) rotate(10);
	-ms-transform: translateY(3px) rotate(45deg);
	-moz-transform: translateY(3px) rotate(45deg);
	-o-transform: translateY(3px) rotate(45deg);
	background-color: #1f2937;
}

.menu-open .menu-lines .line:nth-child(3) {
	transform: translateY(-4px) rotate(135deg);
	-webkit-transform: translateY(0) rotate(10);
	-o-transform: translateY(-5px) rotate(135deg);
	-moz-transform: translateY(-5px) rotate(135deg);
	-ms-transform: translateY(-5px) rotate(135deg);
	background-color: #1f2937;
}

.menu-open .menu-lines .line:nth-child(2) {
	display: none;
}

.menu-lines .line {
	width: 34px;
	height: 2px;
	background-color: #111111;
	display: block;
	margin: 4px auto;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	position: relative;
	z-index: 999;
	margin-top: 4px;
}

.menu-overlay.is-active {
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0.8;
	background: var(--alternative-black, #111);
	backdrop-filter: blur(6px);
	height: 100%;
	transition: 0.6s all ease-in-out;
	width: 100%;
	z-index: 998;
}

.main-menu {
	position: fixed;
	top: -100%;
	transition: 0.3s all;
	opacity: 0;
	visibility: hidden;
	max-height: 100vh;
	overflow-y: auto;
}

.menu-btn {
	width: 73px;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.menu-open .main-menu {
	top: 0;
	opacity: 1;
	visibility: visible;
}

.main-menu {
	background-color: #ff8772;
	width: 100%;
}

.main-menu .main-menu-wrapper {
	display: flex;
	align-items: end;
}

.main-menu .manu-left {
	width: 60%;
	padding: 108px 122px 94px;
	border-right: 0.5px solid #00000026;
}

.main-menu .manu-right {
	width: 40%;
}

.main-menu .manu-left ul li a {
	color: #111;
	font-family: HalenoirCompact-ExtraLight;
	font-size: 64px;
	font-style: normal;
	font-weight: 200;
	line-height: 1.55;
}

.main-menu .manu-left ul li a span {
	width: 15px;
	height: 15px;
	background-color: #000;
	display: inline-block;
	opacity: 0;
	transition: 0.3s;
}

.main-menu .manu-left ul li a:hover span {
	opacity: 1;
}

.manu-right .address-text {
	color: #111;
	font-family: HalenoirCompact-Light;
	font-size: 24px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.2;
	margin-left: 71px;
	width: 100%;
	max-width: 290px;
	margin-bottom: 52px;
}

.manu-right .address-text p {
	margin-bottom: 25px;
}

.manu-right .address-text a {
	color: #111;
	display: block;
}

.manu-right ul {
	padding: 96px 71px 76px;
	display: flex;
	align-items: center;
	gap: 25px;
	border-top: 0.5px solid #00000026;
}

body.menu-open {
	overflow-y: hidden;
	height: 100vh !important;
}

.manu-right .address-text a:hover {
	color: #fff;
}

.manu-right ul a:hover {
	opacity: 0.7;
}
/* --------------- header end --------------- */

/* --------------- footer start --------------- */
footer {
	background-color: #111111;
	padding-top: 92px;
	position: relative;
}

.footer-wrapper {
	max-width: calc(100% - 14vw);
	width: 100%;
	margin: 0 auto;
	padding-top: 17px;
	padding-bottom: 95px;
	display: flex;
	border-top: 1px solid #ffffff;
}

.footer-left {
	width: 100%;
	max-width: 50%;
	border-right: 1px solid #ffffff;
	padding-right: 36px;
	padding-top: 15px;
}

.footer-left .address-text {
	color: #fff;
	font-family: HalenoirCompact-Light;
	font-size: 24px;
	font-style: normal;
	font-weight: 300;
	max-width: 290px;
	width: 100%;
	line-height: 1.2;
}

.footer-left .address-text p {
	margin-bottom: 25px;
	line-height: 1.1;
}

.footer-left .address-text a {
	color: #fff;
	display: block;
}

.footer-left ul {
	display: flex;
	gap: 25px;
	margin: 57px 0;
}

.footer-left-tagline.show-mobile {
	display: none;
}

.footer-left-tagline {
	display: flex;
	justify-content: space-between;
}

.footer-left-tagline p {
	color: #fff;
	font-family: "HalenoirCompact-ExtraLight";
	font-size: 24px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.1;
}

.footer-right {
	width: 100%;
	max-width: 50%;
	padding-left: 52px;
	padding-top: 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.footer-right-links {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

footer a:hover {
	color: #ff8772 !important;
}

.footer-right-links a {
	color: #fff;
	font-family: HalenoirCompact-Light;
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.84;
}

.footer-right-links a:hover {
	opacity: 0.7;
}

.footer-left ul a:hover {
	opacity: 0.7;
}

.apt-residential-text {
	color: #fff;
	font-family: HalenoirCompact-Light;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.31;
	display: flex;
	justify-content: space-between;
}

.apt-residential-text p {
	display: block;
}

.apt-residential-text a {
	color: #fff;
	font-family: HalenoirCompact-Light;
	text-decoration: underline;
}

.social-icons {
	display: none;
}

/* --------------- footer end --------------- */

/* ---------------========== home page css start ==========--------------- */
/*--------------- hero-section start --------------- */
.hero-section {
	min-height: 100vh;
	display: flex;
	align-items: center;
}

.hero-section-wrapper {
	position: relative;
	width: 100%;
	left: -2%;
}

.home-page-hero {
	position: relative;
	z-index: 100;
	margin-bottom: 7px;
	display: flex;
}

.home-page-hero img {
	width: 52vw;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.hero-images img {
	position: absolute;
}

/* --------------- hero section end --------------- */

/* --------------- future-living-section start ---------------  */
.future-living-section h2 {
	color: #ff8772;
	position: relative;
}

.future-living-wrapper {
	display: flex;
	align-items: center;
	padding: 39px 0 27px;
	position: relative;
	justify-content: space-evenly;
}

.future-living-wrapper h2 {

	display: inline-block;
}

.show-mobile {
	display: none;
}

.future-living-wrapper .about-us-content {
	max-width: 27vw;
}

.about-us-content p {
	color: #fff;
	font-size: 17px;
	line-height: 22px;
	font-style: normal;
	font-family: HalenoirCompact-Light;
	width: 100%;
	max-width: 309px;
	margin-bottom: 25px;
}

.future-living-image {
	width: 100%;
	max-width: 17.5vw;
	display: flex;
	position: relative;
	align-items: center;
}

.future-living-1 {
	position: relative;
	z-index: 2;
	left: 8px;
	width: 18vw;
}

.future-living-2 {
	position: absolute;
	z-index: 1;
	width: 28.5vw;
	left: -6.2vw;
}

.future-living-3 {
	position: absolute;
	left: -4.2vw;
	z-index: 0;
	width: 21.5vw;
}

.future-thinkers {
	text-align: right;
	/* padding-top: 7px; */
}

/* --------------- future-living-section end --------------- */

/* --------------- third-image-section start ---------------*/
.third-image-section {
	padding: 83px 0 98px;
}

.third-image-section .third-image-row {
	position: relative;
}

.third-image-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 50;
}

.third-image-section .third-image-row .box-wrapper {
	background: #95dcd5;
	padding: 18px 19px;
	width: 16.9vw;
	display: flex;
	align-items: center;
}

.third-image-section .third-image-row .box-wrapper img {
	width: 100%;
}

.third-image-section .third-image-row video {
	object-fit: cover;
	width: 25.8vw;
	height: 25.8vw;
}

.future-thinkers-2 {
	width: 37.8vw;
	position: relative;
	left: 15px;
	top: -11px;
}

.video-frame-1 {
	width: 17.8vw;
	margin-right: 0;
	display: flex;
	align-items: center;
}

.marquee-text {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 0;
	width: 100vw;
}

.marquee-text img {
	width: 14vw;
}

.marquee-text div {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.third-image-section .third-image-row:first-child {
	margin-bottom: 39px;
}

.video-frame-2 {
	margin-left: -7.3vw;
	display: flex;
	align-items: center;
}

.third-image-section .third-image-row.second-row .box-wrapper {
	background: #111;
	left: -0.4vw;
	position: relative;
}

.future-thinkers-4 {
	margin-right: 3.7vw;
	margin-top: 3.9vw;
	width: 38vw;
}

/* --------------- third-image-section end ---------------*/

/* --------------- crafting-living-section start --------------- */
.crafting-living-section {
	display: flex;
	background: #f0f0f0;
}

.crafting-living-content {
	padding: 83px 20px 28.6vw;
	margin: 0 auto;
	max-width: 760px;
	width: 100%;
}

.crafting-living-video-left .crafting-living-video {
	background: #ff8772;
}

.crafting-living-video {
	height: 50vw;
}

.crafting-living-video video {
	object-fit: cover;
	height: 100%;
}

.crafting-living-left .crafting-living-content p {
	font-family: "HalenoirCompact-ExtraLight";
	/*font-size: 64px;*/
	font-size: 55px;
	font-style: normal;
	margin-bottom: 20px;
	color: #000;
	/*
	background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;*/
}

.crafting-living-right,
.crafting-living-left,
.crafting-living-video-right,
.crafting-living-video-left {
	width: 50%;
}

.a-big-text {
	display: flex;
	justify-content: center;
	padding: 19.2vw 0 17.2vw;
}

.a-big-text img {
	width: 33.4vw;
}

.crafting-living-video {
	padding: 43px;
}

.crafting-living-video-right .crafting-living-video {
	background: #000;
	margin-top: -4px;
}

.crafting-living-video-left {
	margin-top: -21.2vw;
}

.crafting-living-video-left .crafting-living-deatils {
	max-width: 465px;
	width: 100%;
	margin: 0 auto;
	padding: 7.8vw 0px 6vw;
}

.crafting-living-video-left .crafting-living-deatils h3 {
	color: #111;
	font-family: HalenoirCompact-Light;
	font-size: 36px;
	margin-bottom: 30px;
	line-height: 39px;
}

.crafting-living-video-left .crafting-living-deatils p {
	color: #111;
	font-family: "HalenoirCompact-ExtraLight";
	font-size: 17px;
	font-style: normal;
	margin-bottom: 30px;
	max-width: 465px;
	line-height: 1.32;
}

.crafting-living-video-section {
	background-color: #fff;
	display: flex;
}

.crafting-living-video-section .crafting-living-left {
	background-color: #fff;
	display: flex;
}
/* --------------- crafting-living-section end --------------- */

/*--------------- new-standard-section start --------------- */
.new-standard-section {
	padding: 0 60px 155px;
}

.new-standard-section .new-standard-one span {
	margin-right: 89px;
}

/* man on the balcony, word just responsive fix */
/* .new-standard-one span:nth-child(3) {
	float: right;
} */

.new-standard-section .new-standard-two {
	margin-right: 1px;
	display: flex;
	justify-content: space-between;
}

.new-standard-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%);
}

.new-standard-wrapper h2 {
	color: #95dcd5;
	position: relative;
	left: -5.8vw;
	top: -1.39vw;
}

.new-standard-image {
	position: relative;
	width: 100%;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	max-width: 500px;
	width: 100%;
}

.new-standard-image img {
	width: 100%;
}

.new-standard-1 {
	position: absolute;
	right: 0;
	z-index: 50;
}

.new-standard-2 {
	position: relative;
	right: 0;
	z-index: 20;
}

.new-standard-3 {
	position: absolute;
	bottom: 0.9vw;
	right: 0.9vw;
	z-index: 0;
}

/*--------------- new-standard-section end --------------- */

/* --------------- maecenas-maximus-section start --------------- */
.maecenas-maximus-section {
	background-color: #111;
}

.maecenas-maximus-wrapper {
	max-width: calc(100% - 22vw);
	width: 100%;
	margin: 0 auto;
	padding: 65px 20px 54px;
}

.maecenas-maximus-textcontent {
	max-width: 650px;
	width: 100%;
}

.maecenas-maximus-textcontent p {
	color: #fff;
	font-family: HalenoirCompact-ExtraLight;
	font-size: 40px;
	margin-bottom: 28px;
}

.maecenas-maximus-item {
	position: relative;
}

.maecenas-maximus-item.blend-mode-class::before {
	content: "";
	border-radius: 24px;
	background: #d9d9d9;
	mix-blend-mode: color;
	position: absolute;
	width: 100%;
	height: 100%;
}

.maecenas-maximus-item video {
	object-fit: cover;
	border-radius: 24px;
	height: 525px;
	display: block;
	position: relative;
	z-index: -1;
}

.maecenas-maximus-slider .owl-item:nth-child(2n) {
	margin-top: 26px;
}

.maecenas-maximus-slider .video-content-logo-bottom {
	position: absolute;
	bottom: 33px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.maecenas-maximus-slider .video-content-logo-top {
	position: absolute;
	top: 33px;
	left: 50%;
	transform: translateX(-50%);
}

.maecenas-maximus-item .video-content-text {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	max-width: 246px;
	text-align: center;
	transform: translate(-50%, -50%);
	color: #fff;
	font-family: HalenoirCompact-Light;
	font-size: 34px;
	font-weight: 300;
}

.maecenas-maximus-bottom-logo {
	padding: 118px 20px 0;
	display: flex;
	justify-content: center;
}

.maecenas-maximus-bottom-logo img {
	width: 24vw;
}
/* --------------- maecenas-maximus-section end --------------- */

/* ---------------========== home page css end ==========--------------- */

/* ---------------========== about-us page css start ==========--------------- */

/* --------------- about hero section start --------------- */
.new-standard-section.about-ushero-section {
	padding: 10.7vw 44px 10.8vw;
	position: relative;
}

.new-standard-section.about-ushero-section .new-standard-two {
	justify-content: end;
	gap: 36px;
	margin-right: -10px;
}

.new-standard-section.about-ushero-section .new-standard-two span {
	color: #ff8772;
}

.new-standard-section.about-ushero-section .new-standard-wrapper {
	align-items: flex-start;
}

.new-standard-section.about-ushero-section .new-standard-wrapper h2 {
	color: #111111;
}

.new-standard-section.about-ushero-section .new-standard-wrapper p {
	width: 100%;
	max-width: 230px;
	margin-left: 10.5vw;
	margin-top: 6.5vw;
	font-size: 16px;
	line-height: 1.3;
}

.new-standard-for-details {
	width: 100%;
	max-width: 230px;
}

.new-standard-section.about-ushero-section .new-standard-image video {
	width: 100%;
	object-fit: cover;
}

.new-standard-section.about-ushero-section .new-standard-wrapper h2 {
	color: #95dcd5;
	position: relative;
	left: -1vw;
	top: -1vw;
}

/* --------------- about hero section end --------------- */

/* --------------- our mission section start --------------- */
.our-mission-row-one {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-left: auto;
	max-width: 94.5vw;
	padding-bottom: 6vw;
}

.our-mission-text {
	text-align: center;
	margin-top: 7.05vw;
}

.our-mission-text h3 {
	text-align: center;
	font-family: HalenoirCompact-ExtraLight;
	font-size: 36px;
	line-height: 42px;
	font-style: normal;
	font-weight: 200;
	margin-top: 35px;
	max-width: 608px;
	width: 100%;
}

.our-mission-text p span {
	width: 16px;
	height: 16px;
	background: #95dcd5;
}

.our-mission-text p {
	display: flex;
	gap: 12px;
	font-size: 16px;
	font-family: HalenoirCompact-Light;
	align-items: center;
	justify-content: center;
}

.our-mission-img-1 img {
	left: 1.3vw;
	top: 1.5vw;
	position: relative;
	width: 18vw;
}

.our-mission-img-2 img {
	width: 23.5vw;
	margin-top: 4.7vw;
	height: 26.1vw;
}

.our-mission-row-two {
	width: 100%;
	max-width: 92.5vw;
	display: flex;
	justify-content: space-between;
	padding-bottom: 7.9vw;
}

.our-mission-img-3 img {
	position: relative;
	top: 5.3vw;
	width: 21vw;
}

.our-mission-img-4 img {
	position: relative;
	top: 2.2vw;
	left: -0.8vw;
	width: 24.5vw;
}

.our-mission-img-5 img {
	width: 20.6vw;
}

/* --------------- our mission section end --------------- */

/* --------------- our purpose section start --------------- */
.our-purpose-section {
	background-color: #111111;
	padding-bottom: 18.2vw;
}

.our-purpose-section h3 {
	color: #fff;
}

.our-purpose-line-one {
	display: flex;
	justify-content: space-between;
	padding: 112px 8.6vw 123px;
}

.our-purpose-line-one h3 {
	font-family: HalenoirCompact-ExtraLight;
	font-size: 64px;
	font-style: normal;
	font-weight: 200;
	line-height: 1.16;
	width: 100%;
	max-width: 606px;
}

.our-purpose-text {
	max-width: 433px;
	width: 100%;
	color: #fff;
	font-family: HalenoirCompact-Light;
	font-size: 17px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.31;
	padding-top: 13px;
}

.our-purpose-text .sub-title {
	display: flex;
	align-items: center;
	gap: 12px;
	margin-bottom: 22px;
}

.our-purpose-text .sub-title span {
	width: 16px;
	height: 16px;
	background: #ff8772;
	display: block;
}

.our-purpose-line-two {
	padding-right: 43px;
	display: flex;
	justify-content: space-between;
	align-items: end;
}

.our-purpose-line-two .our-purpose-a-logo img {
	position: relative;
	bottom: -2vw;
	width: 100%;
	max-width: 33vw;
}

.founders-list-wrapper {
	display: flex;
	flex-direction: column;
	max-width: 889px;
	width: 100%;
	margin-left: auto;
}

.founders-list-wrapper .founders-list-main:first-child {
	margin-bottom: 5.7vw;
}

.founders-list-main {
	color: #fff;
	font-family: HalenoirCompact-Light;
	display: flex;
	gap: 36px;
	margin-bottom: 8.3vw;
}

.founders-list-main .founder-items {
	width: 100%;
	max-width: 272px;
}

.founders-list-main h4 {
	padding-bottom: 5px;
	border-bottom: 1px solid #fff;
	margin-bottom: 5px;
	font-size: 20px;
}

.founders-list-main p {
	font-size: 15px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.3;
	opacity: 0.5;
}
/* --------------- our purpose section end --------------- */

/* ---------------  about-us-crafting-living-video section start --------------- */
.about-us-crafting-living-video {
	position: relative;
}

.about-us-crafting-living-video .crafting-living-video-left {
	margin-top: -18.3vw;
}

.about-us-crafting-living-video .crafting-living-video-left .crafting-living-video {
	background-color: #95dcd5;
}

.about-us-crafting-living-video .crafting-living-video-right .crafting-living-video {
	background-color: #ff8772;
}

.about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils {
	max-width: 443px;
	width: 100%;
	margin: 0 auto;
	padding: 7.4vw 0px 0;
}

.about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils h3 {
	margin-bottom: 35px;
	line-height: 1.08;
}

.about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils p {
	max-width: 435px;
	line-height: 131.7%;
}

.about-us-crafting-living-video .crafting-living-video-right .blend-mode-class {
	position: relative;
	height: 100%;
}

/* ---------------  about-us-crafting-living-video section end --------------- */

/* --------------- logo-video-section section start --------------- */
.logo-video-section {
	margin-top: 0;
	padding-bottom: 14.2vw;
}

.logo-video-wrapper {
	display: flex;
	justify-content: center;
	align-items: flex-end;

}

.logo-video-wrapper div.full-stop img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.logo-video-wrapper div {
	position: relative;
	width: 100%;
}

.logo-video-wrapper div video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -1;
}

.logo-video-section h2 {
	mix-blend-mode: screen;
	background-color: #ffffff;
	font-size: 60vw;
	line-height: 1;
	font-family: "HalenoirCompact-DemiBold";
	position: relative;
	z-index: 1;
	width: 101%;
}

/* --------------- logo-video-section section end --------------- */

/* --------------- join-the-team-section start --------------- */
.join-the-team-wrapper {
	position: relative;
}

.join-the-team-wrapper video {
	height: 46.5vw;
	width: 100%;
	object-fit: cover;
	display: block;
}

.join-the-team-wrapper::before {
	content: "";
	position: absolute;
	z-index: 5;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%);
}

.join-the-team-textcontent {
	position: absolute;
	bottom: 4.7vw;
	left: 10.8vw;
	z-index: 100;
}

.join-the-team-textcontent h5 {
	font-size: 64px;
	font-style: normal;
	font-weight: 200;
	line-height: 108%;
	color: #fff;
	font-family: HalenoirCompact-ExtraLight;
	max-width: 665px;
	width: 100%;
	margin-bottom: 35px;
}
/* --------------- join-the-team-section end --------------- */

/* ---------------========== about-us page css end ==========--------------- */

/* ---------------========== Our expertise page css start ==========--------------- */

/* --------------- video and text hero section start --------------- */
.video-and-text-wrapper {
	position: relative;
	display: flex;
}

.video-and-text-wrapper .video-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: #000;
	mix-blend-mode: color;
	z-index: 100;
}

.video-and-text-wrapper video {
	height: 45.1vw;
	object-fit: cover;
	min-height: 506px;
}

.video-and-text-wrapper h2 {
	position: absolute;
	left: 12.3vw;
	top: 25%;
	line-height: 1;
	z-index: 200;
}

/* --------------- video and text hero section end --------------- */

/* --------------- our-expertise-content-section start --------------- */
.our-expertise-content-section {
	padding: 6.8vw 0 0;
	background-color: #111111;
}

.our-expertise-content-section p {
	font-family: HalenoirCompact-Light;
	font-size: 36px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.16;
	max-width: 1087px;
	width: 100%;
	margin: 0 auto;
	color: #fff;
}

.our-expertise-content-images {
	position: relative;
	min-height: 300px;
}

.our-expertise-content-images .our-expertise-a-bg img:not(.our-expertise-a-text) {
	width: 100%;
	height: 100%;
}

.our-expertise-content-images .our-expertise-a-bg {
	width: 100%;
	height: 100%;
	position: relative;
	top: 0;
	z-index: 0;
	bottom: 0;
	font-size: 0;
}

.our-expertise-content-images .our-expertise-a-text {
	width: 100%;
	max-width: 17vw;
	margin: 0 auto;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 50;
}

/* --------------- our-expertise-content-section end --------------- */

/* --------------- view-our-team-section start --------------- */
.view-our-team-section {
	padding: 10.77vw 20px 9vw;
}

.content-title {
	color: #111;
	font-family: HalenoirCompact-Light;
	font-size: 36px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.08;
	margin-bottom: 20px;
}

.content-details {
	text-overflow: ellipsis;
	font-family: HalenoirCompact-Light;
	font-size: 17px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.31;
}

.view-our-team-image {
	position: relative;
	max-width: 526.721px;
	left: 50%;
	transform: translateX(-50%);
	padding-top: 20px;
}

.view-our-team-image .view-our-team-1 {
	position: absolute;
	z-index: 50;
	width: 100%;
	transform: rotate(4.075deg);
	left: -0.1vw;
	top: 1.7vw;
}

.view-our-team-image .view-our-team-2 {
	position: absolute;
	z-index: 10;
	width: 100%;
	transform: rotate(-7deg);
	left: -0.7vw;
	top: 2vw;
}

.view-our-team-image .view-our-team-3 {
	position: relative;
	z-index: 0;
	width: 100%;
	transform: rotate(0deg);
	bottom: -2vw;
	left: -0.8vw;
}

.view-our-team-one {
	display: flex;
	justify-content: space-between;
	padding-left: 10.7vw;
	padding-right: 5.9vw;
	margin-bottom: 3vw;
}

.view-our-team-two {
	display: flex;
	justify-content: space-between;
	padding-left: 7vw;
	padding-right: 4.2vw;
	margin-top: -6.8vw;
	margin-bottom: 2.8vw;
}

.view-our-team-content-1 {
	max-width: 386px;
	width: 100%;
	margin-top: 4.2vw;
}

.view-our-team-content-1 h3 {
	margin-bottom: 13px;
}

.view-our-team-content-2 {
	max-width: 419px;
	width: 100%;
}

.view-our-team-content-3 {
	max-width: 346px;
	width: 100%;
	margin-top: 2.9vw;
}

.view-our-team-content-3 h3 {
	max-width: 246px;
	width: 100%;
}

.view-our-team-content-4 {
	max-width: 303px;
	width: 100%;
}

.view-our-team-button {
	display: flex;
	justify-content: center;
}

.view-our-team-button .outline-btn {
	max-width: 151px;
}
/* --------------- view-our-team-section end --------------- */

/* --------------- our values-section start --------------- */
.our-values-section {
	padding: 8vw 72px;
	overflow: hidden;
}

.our-values-first-row {
	display: flex;
}

.our-values-text-character div {
	position: relative;
	overflow: hidden;
}

.our-values-text-character .text-character-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.our-values-text-character .text-character {
	mix-blend-mode: screen;
	background-color: #ffffff;
	width: 100%;
	height: 100%;
}

.our-values-text {
	max-width: 687px;
	width: 100%;
	margin-left: auto;
}

.our-values-text p {
	display: flex;
	align-items: center;
	gap: 12px;
	font-size: 16px;
	font-family: HalenoirCompact-Light;
	margin-bottom: 18px;
}

.our-values-text p span {
	width: 16px;
	height: 16px;
	background: #ff8772;
}

.our-values-text h3 {
	text-align: center;
	font-family: HalenoirCompact-ExtraLight;
	font-size: 64px;
	font-style: normal;
	font-weight: 200;
	margin-top: 17px;
	width: 100%;
	text-align: left;
}

.our-values-content-one {
	display: flex;
	justify-content: space-between;
}

.our-values-content-2 {
	width: 100%;
	max-width: 425px;
	margin-left: 12.4vw;
}

.our-values-content-two {
	display: flex;
}

.our-values-content-two-wrapper {
	width: 100%;
}

.our-values-content-3 {
	width: 100%;
	max-width: 436px;
	padding: 4vw 0;
	margin-left: 7.2vw;
	margin-bottom: 7vw;
}

/* --------------- our values-section end --------------- */

/* ---------------========== Our expertise page css end ==========--------------- */

/* ---------------========== community page css start ==========--------------- */
/* hero section */
.our-community-hero-section .video-and-text-wrapper h2 {
	left: 8.5vw;
	top: 36%;
}
/* --------------- community-content-section start --------------- */
.our-values-section.our-community-content-section {
	padding: 4.6vw 57px 5.8vw;
}

.our-community-content-section .our-values-first-row {
	flex-direction: row-reverse;
	justify-content: space-between;
}

.our-community-content-section .our-values-text {
	margin-left: 6vw;
}

.our-community-content-section .our-values-text h3 {
	margin-left: 3px;
}

.community-hero-image img {
	margin-top: 10.2vw;
	width: 21.5vw;
	height: 29.5vw;
}

/* --------------- community-content-section end --------------- */

/* --------------- dolor-sitamet-section start --------------- */
.dolor-sitamet-1 {
	display: flex;
	margin-left: 5.2vw;
}

.dolor-sitamet-section {
	padding: 3.8vw 0 11.1vw;
	background-color: #f0f0f0;
}

.dolor-sitamet-section p {
	font-family: "HalenoirCompact-Light";
	font-weight: 300;
	font-size: 17px;
	line-height: 1.3;
}

.dolor-sitamet-text-1 {
	margin-left: 4.8vw;
	margin-top: -0.5vw;
}

.dolor-sitamet-text-1 p {
	max-width: 398px;
	width: 100%;
	margin-left: 5.2vw;
	margin-top: 1vw;
}

.dolor-sitamet-img-1 img {
	margin-top: 6vw;
	width: 16.2vw;
	height: 23.2vw;
}

.dolor-sitamet-2 {
	display: flex;
	position: relative;
}

.dolor-sitamet-img-2 {
	display: flex;
	align-items: end;
}

.dolor-sitamet-img-2 img {
	margin-bottom: -12.5vw;
	width: 18.3vw;
	height: 9.9vw;
}

.dolor-sitamet-text-2 {
	margin-top: 6.5vw;
	margin-left: -0.6vw;
}

.dolor-sitamet-text-2 p {
	max-width: 441px;
	width: 100%;
	margin-top: -11px;
	margin-left: 9.1vw;
}

.dolor-sitamet-img-3 {
	position: absolute;
	right: 0;
	top: -7.5vw;
}

.dolor-sitamet-3 {
	display: flex;
	padding-top: 9.9vw;
	position: relative;
	max-width: 74.5vw;
	width: 100%;
	margin-left: auto;
}

.dolor-sitamet-img-4 {
	position: absolute;
	bottom: -7.3vw;
	left: 2.1vw;
}

.dolor-sitamet-text-3 p {
	max-width: 441px;
	padding-top: 53px;
	margin-left: 29.3vw;
}

.dolor-sitamet-img-3 img {
	width: 31vw;
	height: 22vw;
}

.dolor-sitamet-img-4 img {
	width: 14.7vw;
	height: 14.4vw;
}

/* --------------- dolor-sitamet-section end --------------- */

/* --------------- social-responsibility-section start --------------- */
.social-responsibility-section {
	padding: 150px 0 150px;
}

.sustainability-text {
	color: #77d797;
}

.responsibility-text {
	color: #77d797;
	text-align: right;
	margin-right: 1.5vw;
}

.social-responsibility-wrapper {
	display: flex;
	align-items: center;
	margin-left: 2.3vw;
	margin-top: 1.3vw;
}

.social-responsibility-images {
	position: relative;
	max-width: 364px;
	width: 100%;
}

.social-responsibility-images .social-responsibility-img-1 {
	position: relative;
	z-index: 50;
	left: 50%;
	transform: translateX(-50%);
	bottom: -15px;
	width: 100%;
	max-width: 17.5vw;
}

.social-responsibility-images .social-responsibility-img-2 {
	position: absolute;
	z-index: 10;
	left: 0;
	width: 100%;
	max-width: 21.5vw;
}

.social-responsibility-images .social-responsibility-img-3 {
	position: absolute;
	z-index: 0;
	right: 0;
	width: 100%;
	max-width: 20.5vw;
}

.social-responsibility-wrapper h2 {
	margin-left: 3vw;
}

/* --------------- social-responsibility-section end --------------- */
/* ---------------========== community page css start ==========--------------- */

/* ---------------========== contact us page css start ==========--------------- */

/* --------------- contact us form section start --------------- */
.contact-us-form-section {
	padding: 131px 20px 0;
}

.contact-us-title {
	padding: 0 32px;
}

.contact-us-title h2 {
	margin-bottom: 57px;
}

.contact-us-title p {
	font-family: HalenoirCompact-Light;
	margin-bottom: 27px;
}

.contact-us-form-wrapper {
	border-top: 0.5px solid #111111;
	padding: 17px 0;
	display: flex;
	margin: 0 16px;
}

.contact-us-form-left {
	max-width: 50%;
	width: 100%;
	border-right: 0.5px solid #111111;
	padding-top: 59px;
	padding-left: 40px;
	padding-bottom: 80px;
}

.contact-details-item {
	margin-bottom: 48px;
}

.contact-details-item span {
	color: #ff8772;
	font-family: HalenoirCompact-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.09;
	display: block;
	margin-bottom: 9px;
}

.contact-details-item a {
	display: block;
}

.contact-details-item a:hover {
	color: #ff8772;
}

.contect-us-map {
	position: relative;
	width: 100%;
	max-width: 644px;
	padding-right: 37px;
}

.contect-us-map iframe {
	width: 100%;
}

.contact-us-form-right {
	width: 100%;
	max-width: 50%;
	padding: 59px 65px 51px 51px;
}

.input-field {
	margin-bottom: 50px;
}

.input-field label {
	display: block;
	width: 100%;
	padding-left: 7px;
	margin-bottom: 27px;
	color: #111;
	font-family: HalenoirCompact-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.09;
}

.input-field textarea:focus,
.input-field input:focus {
	outline: none;
}

.input-field textarea::placeholder,
.input-field input::placeholder {
	color: #787878;
	font-family: HalenoirCompact-ExtraLight;
	font-size: 16px;
	font-style: normal;
	font-weight: 200;
	line-height: 1.09;
}

.input-field textarea,
.input-field input {
	display: block;
	width: 100%;
	border: 0;
	border-bottom: 0.5px solid #111111;
	padding-left: 7px;
	padding-bottom: 9px;
	font-size: 16px;
}

.send-message-btn {
	margin-top: 96px;
	display: flex;
	justify-content: end;
}

.send-message-btn button {
	max-width: 144px;
	width: 100%;
	cursor: pointer;
	background-color: white;
}
/* --------------- contact us form section end --------------- */

/* ---------------========== contact us page css end ==========--------------- */

/* ---------------========== News page css start ==========--------------- */
.news-hero-section {
	padding-bottom: 21vw;
}

.news-hero-section .video-and-text-wrapper {
	flex-direction: column;
	position: relative;
}

.news-hero-section .video-and-text-wrapper h2 {
	text-align: center;
	width: 100%;
	top: 18%;
	left: 0;
	transform: translateX(0);
}

.first-products-article-item {
	background: #95dcd5;
	padding: 35px 30px;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	width: 100%;
	max-width: 51.5vw;
	z-index: 500;
}

.first-products-article-item img {
	width: 100%;
}

.first-products-article-item p {
	padding: 25px 0;
	font-size: 24px;
	font-style: normal;
	font-family: HalenoirCompact-Light;
	font-weight: 300;
	line-height: 1.09;
	color: #111;
}

.first-products-article-item a {
	max-width: 114px;
}

.blogs-main {
	width: 100%;
	max-width: 92.5vw;
	margin: 0 auto;
	padding: 5vw 0 7vw;
}

.blog-list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 4vw;
}

.blog-list-item {
	padding: 15px 15px 25px 15px;
	background-color: #f0f0f0;
}

.blog-list-item IMG {
	width: 100%;
}

.blog-list-item p {
	padding: 20px 0;
	color: #111;
	font-family: HalenoirCompact-Light;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.09;
}

.blog-list-item a {
	max-width: 114px;
}
/* ---------------========== News page css end ==========--------------- */

/* ---------------========== Article Open css start ==========--------------- */
.main-article-section {
	max-width: 1130px;
	width: 100%;
	margin: 0 auto;
	padding: 8.5vw 20px 6.5vw;
}

.main-article-section h3 {
	color: #111;
	font-family: HalenoirCompact-Light;
	font-size: 36px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.16;
}

.main-article-section p {
	color: #111;
	font-family: HalenoirCompact-Light;
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.31;
}

.article-open-main h3 {
	padding-top: 52px;
}

.article-open-wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 34px;
}

.article-open-text-content {
	max-width: 507px;
	width: 100%;
}

.article-open-text-content P {
	margin-bottom: 21px;
}

.article-open-row-two {
	display: flex;
	padding-top: 4.3vw;
	gap: 2.1vw;
}

.article-open-two-left {
	max-width: 27.3vw;
}

.article-open-two-left h3 {
	margin-top: 4.5vw;
	padding-left: 4px;
}

.article-open-only-text {
	margin-top: 3.3vw;
	padding-left: 4px;
}

.article-open-only-text p {
	margin-bottom: 20px;
}

.other-recent-articles-section {
	border-top: 0.5px solid #111111;
	max-width: 1370px;
	margin: 0 auto;
	width: 100%;
	padding: 8vw 0 0;
}

.other-recent-articles-section .our-values-text {
	margin: 0;
	padding-left: 26px;
}

.other-recent-articles-section .blogs-main {
	padding: 2.2vw 0 7vw;
}

/* ---------------========== Article Open css end ==========--------------- */
